<script setup>
import {
    Bird,
    Book,
    Bot,
    Code2,
    CornerDownLeft,
    LifeBuoy,
    Mic,
    SquareUser,
    Inbox,
    Contact,
    Paperclip,
    Rabbit,
    Settings,
    Share,
    Home,
    Triangle,
    Turtle,
} from "lucide-vue-next";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from "@/components/ui/drawer";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";

import { Link } from '@inertiajs/vue3';
import axios from 'axios';
import { ref, onMounted, computed } from 'vue';
import { CardContainer, CardBody, CardItem } from "@/components/ui/card";
const props = defineProps({ posts: Array, image: String })
const isLoading = ref(false);
const resPost = ref([]);
const messages = ref([]);

onMounted(async () => {
    isLoading.value = true

    try {
        const response = await axios.get('/api/post')
        resPost.value = response.data
    } catch (err) {
        console.log(err)
    } finally {
        isLoading.value = false
    }

    var channel = Echo.channel('posts-channel');
    channel.listen('.posts-event', function(data) {
        // console.log(JSON.stringify(data));
    });

})

</script>

<template>

    <div class="grid h-screen w-full pl-[53px]">
        <aside class="inset-y fixed left-0 z-20 flex h-full flex-col border-r">
            <div class="border-b p-2">
                <Button variant="outline" size="icon" aria-label="Home">
                    <Triangle class="size-5 fill-foreground" />
                </Button>
            </div>
            <nav class="grid gap-1 p-2">
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger as-child>
                            <Button variant="ghost" size="icon" class="rounded-lg bg-muted" aria-label="Playground">
                                <Home class="size-5" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent side="right" :side-offset="5">
                            Home
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger as-child>
                            <Button variant="ghost" size="icon" class="rounded-lg" aria-label="Models">
                                <Bot class="size-5" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent side="right" :side-offset="5">
                            Bots
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger as-child>
                            <Button variant="ghost" size="icon" class="rounded-lg" aria-label="API">
                                <Code2 class="size-5" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent side="right" :side-offset="5"> API </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger as-child>
                            <Button variant="ghost" size="icon" class="rounded-lg" aria-label="Documentation">
                                <Book class="size-5" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent side="right" :side-offset="5">
                            Documentation
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger as-child>
                            <Button variant="ghost" size="icon" class="rounded-lg" aria-label="Settings">
                                <Contact class="size-5" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent side="right" :side-offset="5">
                            Contact
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger as-child>
                            <Button variant="ghost" size="icon" class="rounded-lg" aria-label="Settings">
                                <Settings class="size-5" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent side="right" :side-offset="5">
                            Settings
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </nav>
            <nav class="mt-auto grid gap-1 p-2">
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger as-child>
                            <Button variant="ghost" size="icon" class="mt-auto rounded-lg" aria-label="Help">
                                <LifeBuoy class="size-5" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent side="right" :side-offset="5">
                            Help
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger as-child>
                            <Button variant="ghost" size="icon" class="mt-auto rounded-lg" aria-label="Account">
                                <SquareUser class="size-5" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent side="right" :side-offset="5">
                            Account
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </nav>
        </aside>
        <div class="flex flex-col">
            <header class="sticky top-0 z-10 flex h-[53px] items-center gap-1 border-b bg-background px-4">
                <h1 class="text-xl font-semibold">TanDuy03</h1>
                <Drawer>
                    <DrawerTrigger as-child>
                        <Button variant="ghost" size="icon" class="md:hidden">
                            <Settings class="size-4" />
                            <span class="sr-only">Settings</span>
                        </Button>
                    </DrawerTrigger>
                    <DrawerContent class="max-h-[80vh]">
                        <DrawerHeader>
                            <DrawerTitle>Configuration</DrawerTitle>
                            <DrawerDescription>
                                Configure the settings for the model and messages.
                            </DrawerDescription>
                        </DrawerHeader>
                        <form class="grid w-full items-start gap-6 overflow-auto p-4 pt-0">
                            <fieldset class="grid gap-6 rounded-lg border p-4">
                                <legend class="-ml-1 px-1 text-sm font-medium">Settings</legend>
                                <div class="grid gap-3">
                                    <Label for="model">Model</Label>
                                    <Select>
                                        <SelectTrigger id="model" class="items-start [&_[data-description]]:hidden">
                                            <SelectValue placeholder="Select a model" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="genesis">
                                                <div class="flex items-start gap-3 text-muted-foreground">
                                                    <Rabbit class="size-5" />
                                                    <div class="grid gap-0.5">
                                                        <p>
                                                            Neural
                                                            <span class="font-medium text-foreground">
                                                                Genesis
                                                            </span>
                                                        </p>
                                                        <p class="text-xs" data-description>
                                                            Our fastest model for general use cases.
                                                        </p>
                                                    </div>
                                                </div>
                                            </SelectItem>
                                            <SelectItem value="explorer">
                                                <div class="flex items-start gap-3 text-muted-foreground">
                                                    <Bird class="size-5" />
                                                    <div class="grid gap-0.5">
                                                        <p>
                                                            Neural
                                                            <span class="font-medium text-foreground">
                                                                Explorer
                                                            </span>
                                                        </p>
                                                        <p class="text-xs" data-description>
                                                            Performance and speed for efficiency.
                                                        </p>
                                                    </div>
                                                </div>
                                            </SelectItem>
                                            <SelectItem value="quantum">
                                                <div class="flex items-start gap-3 text-muted-foreground">
                                                    <Turtle class="size-5" />
                                                    <div class="grid gap-0.5">
                                                        <p>
                                                            Neural
                                                            <span class="font-medium text-foreground">
                                                                Quantum
                                                            </span>
                                                        </p>
                                                        <p class="text-xs" data-description>
                                                            The most powerful model for complex computations.
                                                        </p>
                                                    </div>
                                                </div>
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                                <div class="grid gap-3">
                                    <Label for="temperature">Temperature</Label>
                                    <Input id="temperature" type="number" placeholder="0.4" />
                                </div>
                                <div class="grid gap-3">
                                    <Label for="top-p">Top P</Label>
                                    <Input id="top-p" type="number" placeholder="0.7" />
                                </div>
                                <div class="grid gap-3">
                                    <Label for="top-k">Top K</Label>
                                    <Input id="top-k" type="number" placeholder="0.0" />
                                </div>
                            </fieldset>
                            <fieldset class="grid gap-6 rounded-lg border p-4">
                                <legend class="-ml-1 px-1 text-sm font-medium">Messages</legend>
                                <div class="grid gap-3">
                                    <Label for="role">Role</Label>
                                    <Select default-value="system">
                                        <SelectTrigger>
                                            <SelectValue placeholder="Select a role" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="system"> System </SelectItem>
                                            <SelectItem value="user"> User </SelectItem>
                                            <SelectItem value="assistant"> Assistant </SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                                <div class="grid gap-3">
                                    <Label for="content">Content</Label>
                                    <Textarea id="content" placeholder="You are a..." />
                                </div>
                            </fieldset>
                        </form>
                    </DrawerContent>
                </Drawer>
                <Button variant="outline" size="sm" class="ml-auto gap-1.5 text-sm">
                    <Inbox class="size-3.5" />
                    Mail
                </Button>
            </header>
            <section class="bg-white dark:bg-gray-900">
                <div class="pb-8 px-4 mx-auto max-w-screen-xl lg:py-10 lg:px-6">
                    <div class="mx-auto max-w-screen-sm text-center lg:mb-10 mb-8">
                        <h1 v-for="message in messages" :key="message">{{ message }}</h1>
                        <h2
                            class="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                            Our Blog</h2>
                        <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">We use an agile approach to
                            test assumptions and connect with the needs of your audience early and often.</p>
                    </div>
                    <div class="grid gap-5 lg:grid-cols-3 relative">

                        <CardContainer v-for="(post, index) in resPost" :key="post.id">
                            <CardBody
                                class="group/card relative size-auto rounded-xl border border-black/[0.1] bg-gray-50 p-6 dark:border-white/[0.2] dark:bg-black dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1]"
                            >
                                <CardItem
                                    :translate-z="50"
                                    class="text-xl font-bold text-neutral-600 dark:text-white line-clamp-2"
                                >
                                    {{ post.title }}
                                </CardItem>
                                <CardItem
                                    as="p"
                                    translate-z="60"
                                    class="mt-2 max-w-sm text-sm text-neutral-500 dark:text-neutral-300 line-clamp-2"
                                >
                                    {{ post.description }}
                                </CardItem>
                                <CardItem
                                    :translate-z="100"
                                    :rotate-x="20"
                                    :rotate-z="-10"
                                    class="mt-4 w-full"
                                >
                                    <img
                                        :src="(post.image || '').startsWith('https') || (post.image || '').startsWith('http') ? post.image : `/storage/${post.image}`"
                                        height="1000"
                                        width="1000"
                                        class="h-60 w-full rounded-xl object-cover group-hover/card:shadow-xl"
                                        :alt="post.title"
                                    />
                                </CardItem>
                                <div class="mt-16 px-4">
                                    <span class="flex gap-2 text-[16px] text-gray-700"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-alarm-clock"><circle cx="12" cy="13" r="8"/><path d="M12 9v4l2 2"/><path d="M5 3 2 6"/><path d="m22 6-3-3"/><path d="M6.38 18.7 4 21"/><path d="M17.64 18.67 20 21"/></svg>
                                    {{ post.created_at_human }}</span>
                                </div>
                                <div class="mt-5 flex items-center justify-between">
                                    <Link :href="`/post/${post.slug}`">
                                        <CardItem
                                            :translate-z="20"
                                            :translate-x="-40"
                                            as="a"
                                            class="rounded-xl px-4 py-2 text-xs font-normal dark:text-white"
                                        >
                                            Visit →
                                        </CardItem>
                                    </Link>
                                    <CardItem
                                        :translate-z="20"
                                        :translate-x="40"
                                        as="button"
                                        class="rounded-xl bg-black px-4 py-2 text-xs font-bold text-white dark:bg-white dark:text-black"
                                    >
                                        Get Started
                                    </CardItem>
                                </div>
                            </CardBody>
                        </CardContainer>
                        <div class="absolute size-full flex justify-center items-center"  :class="{ hidden: !isLoading }">
                            <div class="loader" :class="{ hidden: !isLoading }"></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

</template>

<style scoped>
/* HTML: <div class="loader"></div> */
.loader {
    height: 30px;
    aspect-ratio: 2.5;
    --_g: no-repeat radial-gradient(farthest-side, #000 90%, #0000);
    background: var(--_g), var(--_g), var(--_g), var(--_g);
    background-size: 20% 50%;
    animation: l43 1s infinite linear;
}

@keyframes l43 {
    0% {
        background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%
    }

    16.67% {
        background-position: calc(0*100%/3) 0, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%
    }

    33.33% {
        background-position: calc(0*100%/3) 100%, calc(1*100%/3) 0, calc(2*100%/3) 50%, calc(3*100%/3) 50%
    }

    50% {
        background-position: calc(0*100%/3) 50%, calc(1*100%/3) 100%, calc(2*100%/3) 0, calc(3*100%/3) 50%
    }

    66.67% {
        background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 100%, calc(3*100%/3) 0
    }

    83.33% {
        background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 100%
    }

    100% {
        background-position: calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%
    }
}
</style>
